<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="场地名称">
          <el-input v-model="search.areaname" clearable placeholder="请输入场地名称"></el-input>
        </el-form-item>
         <el-form-item label="选择社区">
          <el-select
            v-model="search.communityCode"
            clearable
            placeholder="请选择社区"
          >
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否启用">
          <el-select
            v-model="search.areaflag"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in useFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="success" @click="operatePlace('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="tableData" stripe>
      <el-table-column prop="areaname" label="场地名称"> </el-table-column>
      <el-table-column
        label="场地图片">
        <template slot-scope="scope">
          <div class="imgbox_table" :style="{ 'background-image' : `url(${scope.row.showpic})`}"></div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="场地地址"> </el-table-column>
      <el-table-column prop="remark" label="场地描述"> </el-table-column>
      <el-table-column prop="points" label="收取积分"> </el-table-column>
      <el-table-column prop="days" label="可显示预约天数"> </el-table-column>
      <el-table-column prop="areaflag" label="是否启用" :formatter="areaFlagFormatter"></el-table-column>
      <el-table-column label="操作" fixed="right" width="280" align="center">
        <template slot-scope="scope">
          <el-button @click="operatePlace('view', scope.row)" type="primary">查看</el-button>
          <el-button @click="operatePlace('update', scope.row)" type="primary">修改</el-button>
          <el-button v-if="scope.row.areaflag == 0" @click="deletePlace(scope.row, scope.row.areaflag)" type="danger">停用</el-button>
          <el-button v-else @click="deletePlace(scope.row, scope.row.areaflag)" type="primary" plain>启用</el-button>
          <el-button @click="deleteSite(scope.row)" type="warning">删除</el-button>
        </template>
      </el-table-column>


    </el-table>
    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>


    <el-dialog :title="operateTitle" :visible.sync="dialogFormVisible" :before-close="beforeCloseFn">
      <el-form :model="form" :rules="rules" ref="form" style="width: 90%" :label-width="formLabelWidth">
        <el-form-item label="场地名称" prop="areaname">
          <el-input v-model="form.areaname" :disabled="lookDetailFlag" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="场地图片" prop="showpic">
          <el-upload
            action="#"
            :class="{'upload-hide': hideUploadEdit}"
            :auto-upload="false"
            accept="image/*"
            :show-file-list="true"
            list-type="picture-card"
            :on-change="imgChange"
            :file-list="picList"
          >
            <div slot="file" slot-scope="{file}">
              <img :src="file.url"  style="width:100%;height:146px !important">
              <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="bigImg(file)"><i class="el-icon-zoom-in"></i></span>
                  <span class="el-upload-list__item-delete" @click="imgRemove(file)" v-if="!lookDetailFlag">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
            </div>
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="场地地址" prop="address">
          <el-input v-model="form.address" :disabled="lookDetailFlag" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="场地描述" prop="remark">
          <el-input v-model="form.remark" :disabled="lookDetailFlag" autocomplete="off" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="积分类型" prop="iscommonArr">
          <el-checkbox-group v-model="form.iscommonArr" :disabled="lookDetailFlag">
            <el-checkbox label="1">普通积分</el-checkbox>
            <el-checkbox label="0">荣誉积分</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="收取积分" prop="points">
          <el-input v-model="form.points" :disabled="lookDetailFlag" autocomplete="off" oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')">
            <i slot="suffix" style="font-style: normal; margin-right: 10px; color: #333;">积分</i>
          </el-input>
        </el-form-item>
        <el-form-item label="可预约社区" prop="checkedCommunitys">
          <el-checkbox-group :disabled="lookDetailFlag" v-model="form.checkedCommunitys">
            <el-checkbox v-for="item in communitys" :label="item.levelcode" :key="item.levelcode">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="可显示预约天数" prop="days">
          <el-input v-model="form.days" :disabled="lookDetailFlag" autocomplete="off" oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')">
            <i slot="suffix" style="font-style: normal; margin-right: 10px; color: #333;">天</i>
          </el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="areaflag">
          <el-select
            v-model="form.areaflag"
            clearable
            placeholder="请选择"
            :disabled="lookDetailFlag"
          >
            <el-option
              v-for="item in useFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="operateFlag == 'add'" @click="resetForm('form')">重置</el-button>
        <el-button type="primary" @click="submitForm('form')" v-if="!lookDetailFlag">确认{{operateFlag == 'add'?'新增':'修改'}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="operateFlag=='add'?'选择关联门禁':'请重新选择关联门禁'" :visible.sync="dialogTableVisible" :before-close="beforeSelectionCloseFn">
      <div class="searchbox">
        <el-form :inline="true">
          <el-form-item label="门禁名称">
            <el-input v-model="doorname" autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doorSearch">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="doorLists" ref="table" :row-key="setSelectionIdFn"  stripe @selection-change="handleSelectionChange" ><!-- highlight-current-row @current-change="handleSelectionChange" -->
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55">
        </el-table-column>
        <el-table-column property="valuebane" label="门禁名称"></el-table-column>
        <el-table-column property="street_name" label="备注"></el-table-column>
        <el-table-column property="doorcode" label="编号"></el-table-column>
      </el-table>
      <div class="paginationbox">
        <el-pagination
          @size-change="handleSizeChangeOfDoor"
          @current-change="handleCurrentChangeOfDoor"
          :current-page="currentDoorPage"
          :page-sizes="doorFenye.pagesizes"
          :page-size="doorFenye.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="doorFenye.totalnum"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSelection">取 消</el-button>
        <el-button type="primary" :disabled="multipleSelection.length==0" @click="sureDoorSection">确认选择</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  deletePlace,
  doorListAboutPlace,
  addOrUpdateInfo,
  querySitePage,
  delSite,
  siteDetail,
} from "@/api/acitvity/index.js";
import { upLoadFileObj } from '@/api/itemCategory'

import { communityOfUser } from '@/api/system';
export default {
  name: "",
  components: {},
  props: {
    actuuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      communitys:[],
      useFlags:[{
        value: '0',
        label: '启用'
      },{
        value: '1',
        label: '禁用'
      }],
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      currentPage: 1,
      search: {
        areaname: '',
        areaflag :'',
        communityCode:''
      },
      operateFlag: '',
      operateTitle: '',
      dialogFormVisible: false,
      form: {
        areaname:'', //场地名称
        address: '', //场地地址
        remark: '', //场地描述
        showpic: '', //场地图片
        iscommonArr: [],
        points: '', //收取积分
        days: '', //可显示预约天数
        areaflag: '', //是否启用
        // communityCodes: '', //社区code（逗号分隔）
        checkedCommunitys: [],
      },
      rules:{
        areaname: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        showpic: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        iscommonArr: [
          { required: true, message: '请选择积分类型', trigger: 'change' }
        ],
        points: [
          { required: true, message: '请输入收取积分', trigger: 'blur' }
        ],
        days: [
          { required: true, message: '请输入可显示预约天数', trigger: 'blur' }
        ],
        areaflag:[
          { required: true, message: '请选择是否启用', trigger: 'change' }
        ],
        checkedCommunitys:[
          { required: true, message: '请选择社区', trigger: 'change' }
        ],
      },
      formLabelWidth: '180px',
      placeId: '',
      doorLists:[],
      dialogTableVisible: false,
      multipleSelection: [],
      singleSelection: null,
      currentDoorPage:1,
      doorFenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      doorname: '',
      hideUploadEdit: false,
      picList: [],
      dialogImageUrl: '',
      imgDialogVisible: false,
      lookDetailFlag: false,
    };
  },
  watch: {},
  created() {
    this.getCommunityOfUser();
    this.getList();
  },
  mounted() {},
  computed: {},
  methods: {
    areaFlagFormatter(row) {
      if(row.areaflag == 0) {
        return '启用'
      } else if(row.areaflag == 1) {
        return '停用'
      }
    },
    //场地图片
    imgChange(file, fileList) {
      this.hideUploadEdit = fileList.length>0;
			let formData = new FormData()
      formData.append('file', file.raw);
			upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
          contentType: 'image/*'
			}).then((res)=>{
				if(res.result==200){
					this.$message.success('图片上传成功')
          let url = res.detail[0].path;
          this.picList.push({name:res.detail[0].id, url})
          this.hideUploadEdit = true;
          this.form.showpic = url;
				}else{
					this.$message.error('图片上传失败')
				}
			})
    },
    //图片预览或附件下载
    bigImg(file) {
      let url = file.url;
      this.dialogImageUrl = url;
      this.imgDialogVisible = true;
    },
    //场地图片删除
    imgRemove(file) {
			this.picList.map((item,index)=>{
				if(item.name==file.name){
					this.picList.splice(index,1)
				}
			})
      this.form.showpic = '';
      this.hideUploadEdit = this.picList.length>0
    },
    //给表格选择框设置唯一标识row-key
    setSelectionIdFn(row){
      // console.log(row)
      return row.doorcode;
    },
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
          // this.getList();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    getList() {
      querySitePage({
        userid: this.GLOBAL.adminId(),
        pagesize: this.fenye.pagesize,
        page: this.currentPage,
        communityCode: this.search.communityCode,
        areaflag: this.search.areaflag,
        areaname: this.search.areaname,
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //切换页面条数
    handleSizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //搜索
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //添加、修改场地
    operatePlace(type, row){
      this.operateFlag = type;
      this.dialogFormVisible = true;
      this.lookDetailFlag = type == 'view' ? true : false;
      if(type == 'add') {
        this.operateTitle = '新增场地'
      } else if(type == 'update') {
        this.operateTitle = '编辑场地'
      } else if(type == 'view') {
        this.operateTitle = '场地信息'
      }
      if(row){
        this.placeId = row.id;
        this.getSiteDetail(row.id);
      }
    },
    getSiteDetail(id) {
      siteDetail({
        id: id
      }).then(res => {
        if(res.result == 200) {
          let data = res.detail;
          let communityCodes = data.codes;
          this.form = {
            areaname: data.areaname, //场地名称
            address: data.address, //场地地址
            remark: data.remark, //场地描述
            showpic: data.showpic, //场地图片
            points: data.points, //收取积分
            days: data.days, //可显示预约天数
            areaflag: data.areaflag + '', //是否启用
            iscommonArr: data.iscommon ? data.iscommon.split(',') : [],
            // communityCodes: '', //社区code（逗号分隔）
            checkedCommunitys: communityCodes ? communityCodes.split(',') : '',
          }
          this.picList =  data.showpic ? [{
            name: '图片',
            url: data.showpic
          }] : [];
          this.hideUploadEdit = data.showpic ? true : false;
        }
      })
    },
    deleteSite(row) {
      this.$confirm('是否确定删除该场地?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSite({
          userid: this.GLOBAL.adminId(),
          id: row.id
        }).then(res=>{
          if (res.result == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.onSearch();
          } else {
            this.$message.error(res.description);
          }
        })
      }).catch(() => {});
    },
    //删除场地
    deletePlace(row, flag){
      let that = this;
      let message = flag == 0 ? '是否确定停用该场地?' : '是否确定启用该场地?'
      this.$confirm( message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        siteDetail({
          id: row.id
        }).then(res => {
          if(res.result == 200) {
            let data = res.detail;
            let areaflag = data.areaflag == 0 ? 1 : 0;
            var form = {
              userid: that.GLOBAL.adminId(),
              id: data.id,
              areaname: data.areaname, //场地名称
              address: data.address, //场地地址
              remark: data.remark, //场地描述
              showpic: data.showpic, //场地图片
              points: data.points, //收取积分
              days: data.days, //可显示预约天数
              areaflag: areaflag, //是否启用
              communityCodes: data.codes, //社区code（逗号分隔）
            }
            addOrUpdateInfo(form).then(res=>{
              if (res.result == 200) {
                that.$message({
                  type: 'success',
                  message: '操作成功!'
                });
                that.onSearch();
              } else {
                that.$message.error(res.description);
              }
            })
          }
        })
      }).catch(() => {});
    },
    //获取门禁列表
    getDoorListAboutPlace(){
      doorListAboutPlace({
        userid: this.GLOBAL.adminId(),
        doorname: this.doorname,
        page: this.currentDoorPage,
        pagesize: this.doorFenye.pagesize
      }).then(res=>{
        if(res.result == 200){
          this.doorLists =  res.detail.list;
          this.doorFenye.totalnum =  res.detail.totalRow;
          
         /*  //如果是编辑时，则选中之前的状态（用户重新选择）
          if(this.operateFlag == 'update'){
            console.log(this.form.doorcode)
            let arr = this.form.doorcode.split(',')
            console.log(arr)
            let newArr = []
            arr.map(i=>{
              console.log(i)
              this.doorLists.map(item=>{
                if(i == item.doorcode){
                  newArr.push(item)
                }
              })
            })
            
            console.log(newArr)
            this.toggleSelection(newArr)
          } */
        } else {
          this.$message.error(res.description);
        }
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.table.toggleRowSelection(row);
        });
      } else {
        this.$refs.table.clearSelection();
      }
    },
    //选择关联门禁
    connectDoor(){
      this.currentDoorPage = 1;
      this.getDoorListAboutPlace();
      this.$nextTick(()=>{
        this.dialogTableVisible = true;
      })
    },
    //多选
    handleSelectionChange(val) {
      // this.singleSelection = val;
      this.multipleSelection = val;
    },
    //门禁切换页面条数
    handleSizeChangeOfDoor(val) {
      this.doorFenye.pagesize = val;
      this.currentDoorPage = 1;
      this.getDoorListAboutPlace();
    },
    //门禁切换分页
    handleCurrentChangeOfDoor(val) {
      this.currentDoorPage = val;
      this.getDoorListAboutPlace();
    },
    //取消门禁选择
    cancelSelection(){
      this.dialogTableVisible = false;
      if(this.form.doorname == '' && this.multipleSelection.length>0){
        this.$refs.table.clearSelection();
      }
    },
    //确认门禁选择
    sureDoorSection(){
      let doorcodes = [];
      let doornames = [];
      this.multipleSelection.map(item=>{
        doorcodes.push(item.doorcode)
        doornames.push(item.valuebane)
      })
      // this.form.doorcode = this.singleSelection.doorcode;
      // this.form.doorname = this.singleSelection.valuebane;
      this.form.doorcode = doorcodes.join(',');
      this.form.doorname = doornames.join(',');
      this.dialogTableVisible = false;
    },
    //门禁搜索
    doorSearch(){
      this.currentDoorPage = 1;
      this.getDoorListAboutPlace();
    },
    //新增或编辑提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.form;
          data.userid = this.GLOBAL.adminId();
          data.communityCodes = this.form.checkedCommunitys.join(',');
          data.iscommon = this.form.iscommonArr.join(',');
          if(this.operateFlag == 'add'){
            addOrUpdateInfo(data).then(res=>{
              if(res.result == 200){
                this.resetForm('form');
                this.dialogFormVisible = false;
                this.onSearch();
                if(this.$refs.table){
                  this.$refs.table.clearSelection();
                }
              } else {
                this.$message.error(res.description);
              }
            })
          }else if(this.operateFlag == 'update'){
            data.id = this.placeId;
            addOrUpdateInfo(data).then(res=>{
              if(res.result == 200){
                this.resetForm('form');
                this.dialogFormVisible = false;
                if(this.$refs.table){
                  this.$refs.table.clearSelection();
                }
                this.onSearch();
              } else {
                this.$message.error(res.description);
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      if(this.multipleSelection.length>0){
        this.$refs.table.clearSelection();
      }
    },
    //关闭前重置表单
    beforeCloseFn(done){
      this.resetForm('form');
      this.form.doorcode = '';
      this.picList = [];
      if(this.multipleSelection.length>0){
        this.$refs.table.clearSelection();
      }
      done();
    },
    beforeSelectionCloseFn(done){
      if(this.form.doorname == '' && this.multipleSelection.length>0){
        this.$refs.table.clearSelection();
      }
      done();
    }
  },
};
</script>

<style lang="scss" scoped>
.searchbox {
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
.dialog-footer{
  margin-top: -20px;
  padding: 0 50px 20px;
}
// 隐藏上传按钮
  ::v-deep {
    .upload-hide{
      &>div.el-upload--picture-card {
        display: none;
      }
    }
  }
  .imgbox_table{
    height: 80px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    // background-position: center;
  }
</style>